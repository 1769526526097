html {
  font-size: 16px;
}

@media (min-width: 600px) {
  html {
    font-size: 12px;
  }
}

@media (min-width: 1000px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "JosefinSans-Light";
  src: url('./assets/fonts/JosefinSans-Light.ttf') format('truetype');
}

@font-face {
  font-family: "JosefinSans-Regular";
  src: url('./assets/fonts/JosefinSans-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "JosefinSans-SemiBold";
  src: url('./assets/fonts/JosefinSans-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "JosefinSans-Bold";
  src: url('./assets/fonts/JosefinSans-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat-Light";
  src: url('./assets/fonts/Montserrat-Light.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat-Regular";
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: url('./assets/fonts/Montserrat-SemiBold.ttf') format('truetype');
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: "Caramello-Free";
  src: url('./assets/fonts/Caramello Free.ttf') format('truetype');
}