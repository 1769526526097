.enlighten-tv-video {
  width: 100%;
}

.btn-red-rounded {
  background: '#BF357A';
  font-weight: 'bold';
  border-radius: '2rem';
  padding: '9px 28px';
  color: '#FFFFFF';
}

@media (min-width: 600px) {
  .enlighten-tv-video {
    width: 45%;
  }
}

@media (min-width: 900px) {
  .enlighten-tv-video {
    width: 30%;
  }
}
